<template>
    <div class="Notification-wrapper">
        <v-container class="pt-2 mb-12">
            <v-row align="center" class="mt-4 item" v-for="(item, index) in notification" :key="index">
                <v-col v-if="isOwnInitiator(item)" cols="2" class="background-item">
                    <profile-image
                        :src="getAvatarUrl(item)"
                        :username="getName(item)"
                    />
                </v-col>
                <v-col v-else cols="1" class="background-item mr-5">
                    <router-link :to="getRouterLink(item)">
                        <profile-image
                            :src="getAvatarUrl(item)"
                            :username="getName(item)"
                        />
                    </router-link>
                </v-col>
                <v-col cols="8" class="background-item">
                    <div>
                        <span v-if="isOwnInitiator(item)" class="title-notification">
                            {{ item.data.text }}
                        </span>
                        <span v-else class="title-notification">
                             <router-link :to="getRouterLink(item)">
                                {{ item.data.text }}
                             </router-link>
                        </span>
                        <span class="hint">{{ moment(item.created_at).fromNow() }}</span>
                    </div>
                </v-col>
                <v-spacer/>
                <div class="icon-notification fill-height">
                    <icon-cross v-if="item.data.icon === 'icon-unsubscribe'"/>

                    <!-- deprecated streaming icon, remove -->
                    <icon-signal v-else-if="item.data.icon === 'icon-signal'"/>

                    <icon-danger v-else-if="item.data.icon === 'icon-danger'"/>

                    <icon-account-link v-else-if="item.data.icon === 'icon-account-link'"/>

                    <v-icon v-else-if="item.data.icon === 'icon-holiday-mood'">mdi-palm-tree</v-icon>

                    <icon-dream-goal v-else-if="item.data.icon === 'icon-dream-goal'"/>

                    <app-icon v-else :icon="item.data.icon" size="21"/>
                </div>
            </v-row>
        </v-container>
        <bottom-bar active="active"/>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import BottomBar from "@/components/app/navigation/BottomBar";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import IconCross from "@/assets/images/icons/cross.svg?inline"
import IconSignal from "@/assets/images/icons/signal.svg?inline"
import IconDanger from "@/assets/images/icons/danger.svg?inline"
import IconAccountLink from "@/assets/images/icons/account-link.svg?inline"
import IconDreamGoal from "@/assets/images/icons/piggy-bank-noty.svg?inline"
import HolidayMoodIcon from "@/components/app/icons/HolidayMoodIcon";

export default {
    name: "Notification",
    components: {
        BottomBar,
        ProfileImage,
        IconCross,
        IconSignal,
        IconDanger,
        IconAccountLink,
        HolidayMoodIcon,
        IconDreamGoal
    },
    data: () => ({
        notification: []
    }),
    methods: {
        ...mapActions([
            'readNotifications',
        ]),
        textByType(type, count = '') {
            if (type === 'following')
                return ['is now following you', 'follow']
            if (type === 'subscribed')
                return ['subscribed to your profile', 'subscribe']
            if (type === 'own__verified')
                return ['been verified', 'star']
            if (type === 'tips')
                return [`sent you a ${count}$ tip`, 'tip']
        },
        isOwnInitiator(item) {
            const slug = 'initiator' in item.data ? item.data.initiator.slug : false
            return !slug || slug === this.user.slug
        },
        getAvatarUrl(item) {
            const url = 'initiator' in item.data ? item.data.initiator.avatar_url : this.user.profileImage
            if (!url) {
                return ''
            }
            return url
        },
        getName(item) {
            return 'initiator' in item.data ? item.data.initiator.name : this.user.name
        },
        getRouterLink(item) {
            if (item.data.type) {
                switch(item.data.type) {
                    case 'stream_scheduled':
                    case 'stream_started':
                        return {name: 'otherUser.profile.viewStreams', params: {slug: item.data.initiator.slug}}
                }
            }

            const id = 'initiator' in item.data ? item.data.initiator.id : false
            return {name: 'otherUser.profile.viewSlug', params: {slug: item.data.initiator.slug, from: this.$route.name}}
        },
    },
    computed: {
        ...mapState({
            user: state => state.user.user.data
        }),

    },
    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Notifications');
        this.$store.dispatch('getNotifications').then((q) => {
            this.notification = q
        })
        this.readNotifications()
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';
$image-size: 40px;
.Notification {
    &-wrapper {
        min-height: 100%;
    }
}

.unblock {
    color: #FE4811;
    font-size: 0.9em;
}

.item {
    background: #1A1A1A;
    border-radius: 18px;
    min-height: $image-size+20px;
    position: relative;
}

.background-item {

}

.title-notification {
    font-size: 0.7em;
    display: block;

}

.icon-notification {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $accent-background;
    border-radius: 0 18px 18px 0;
    width: 40px;
    height: 100%;
    position: absolute;
    right: 0px;
}

.hint {
    color: rgba(255, 255, 255, 0.5)
}
</style>
